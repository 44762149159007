define(['$window'], ($window) => {

  const footerSubsiteSelector = () => {

    const component = {};

    const _config = {
      selectors: {
        selector: '.footerSubsiteSelector_select',
        selectorText: '.footerSubsiteSelector_select_text',
        header: '[data-js-element=header]',
        dropdownList: '[data-js-element=footer-subsite-selector-nav-list]',
        dropdownListContainer: '[data-js-element=footer-subsite-selector-list-container]',
        dropdownOption: '[data-js-element=footer-subsite-link]'
      },
      elements: {
        optionSelected: undefined,
      },
      classNames: {
        dropdownOnTop: 'footerSubsiteSelector_navList-onTop',
        dropdownOnBottom: 'footerSubsiteSelector_navList-onBottom',
        selected: 'selected',
        hide: 'hide'
      },
      attribs: {
        subsiteCode: 'data-subsite-code'
      }
    };

    const _init = (element) => {
      component.element = element;
      component.selector = component.element.querySelector(component.config.selectors.selector);
      component.selectorText = component.element.querySelector(component.config.selectors.selectorText);
      component.dropdownList = component.element.querySelector(component.config.selectors.dropdownList);
      component.dropdownListContainer = component.element.querySelector(component.config.selectors.dropdownList);
      component.dropdownOptions = component.element.querySelectorAll(component.config.selectors.dropdownOption);


      component.highlightOption();
      component.bind();

      return component;
    };

    const _bind = () => {
      component.selector.addEventListener('click', component.selectorClick);
      component.selector.addEventListener("keydown", (event) => {
        if (event.which === 13 || event.keyCode === 13 || event.key === "Enter") {
          component.selectorClick();
        }
      });

      Array.from(component.dropdownOptions).map(option => option.addEventListener('mouseover', component.optionHovered));

      //Detect outside click to close the dropdown
      $window.addEventListener('click', () => !component.dropdownList.classList.contains(component.config.classNames.hide) && component.toggleDropdownDisplay());
      $window.addEventListener('resize', () => !component.dropdownList.classList.contains(component.config.classNames.hide) && component.setDropdownHeight());

      component.keyPress();
    };

    const _optionHovered = (e) => {
      for (let i = 0; i < component.dropdownOptions.length; i++) {
        if (component.dropdownOptions[i].getAttribute(component.config.attribs.subsiteCode) === e.target.getAttribute(component.config.attribs.subsiteCode)) {
          if (component.config.elements.optionSelected) {
            component.config.elements.optionSelected.classList.remove(component.config.classNames.selected);
          }
          component.currentOptionIndex = i;
          break;
        }
      }
      component.config.elements.optionSelected = e.target;
      component.config.elements.optionSelected.classList.add(component.config.classNames.selected);
      component.config.elements.optionSelected.focus();
    };

    const _highlightOption = () => {
      const locale = component.selectorText.getAttribute(component.config.attribs.subsiteCode);
      const selector = `[${component.config.attribs.subsiteCode}=${locale}]`;

      component.config.elements.optionSelected = component.dropdownListContainer.querySelector(selector);
      component.config.elements.optionSelected.classList.add(component.config.classNames.selected);
      component.config.elements.optionSelected.focus();
      component.currentOptionIndex = 0;
    };

    const _selectorClick = (e) => {
      if(e){
        e.stopPropagation();
      }
      
      // Toggle aria-expanded
      const newAriaExpandedValue =  component.selector.getAttribute('aria-expanded') === 'true' ? 'false' : 'true';
      component.selector.setAttribute('aria-expanded', newAriaExpandedValue);
      
      if (component.dropdownList.classList.contains(component.config.classNames.dropdownOnTop) || component.dropdownList.classList.contains(component.config.classNames.dropdownOnBottom)) {
        component.toggleDropdownDisplay();
      }
      else {
        component.setDropdownHeight();
      }
    };

    const _setDropdownHeight = () => {
      const selectorElement = component.selector.getBoundingClientRect();
      const spaceBelow = window.innerHeight - selectorElement.bottom;
      const viewportHeight = document.documentElement.clientHeight;
      const headerHeight  = 200;
      const heightOffSet = spaceBelow + headerHeight;
      
      const newDropdownHeight = viewportHeight - heightOffSet;
      
      const selectorHeight = component.selector.offsetHeight + "px";
      
      if (newDropdownHeight > spaceBelow) {
        component.toggleDropdownDisplay(component.config.classNames.dropdownOnTop);
        component.dropdownList.style.bottom = selectorHeight;
        component.dropdownList.style.removeProperty('top');
      }
      else {
        component.toggleDropdownDisplay(component.config.classNames.dropdownOnBottom);
        component.dropdownList.style.top = selectorHeight;
        component.dropdownList.style.removeProperty('bottom');
      }
    };

    const _keyPress = () => {
      document.addEventListener("keydown", (e) => {
        if (component.dropdownList.classList.contains(component.config.classNames.dropdownOnTop) || component.dropdownList.classList.contains(component.config.classNames.dropdownOnBottom)) {
          if (e.key === 'ArrowUp' || e.key === 'Up' || e.key === 'ArrowDown' || e.key === 'Down' || e.key === 'Escape' || e.key === 'Esc' || e.key === 'Tab') {
            e.stopPropagation();
            e.preventDefault();
          }
          if ((e.key === 'ArrowUp' || e.key === 'Up') && component.config.elements.optionSelected && component.currentOptionIndex !== 0) {
            component.config.elements.optionSelected.classList.remove(component.config.classNames.selected);
            component.currentOptionIndex -= 1;
            component.config.elements.optionSelected = component.dropdownOptions[component.currentOptionIndex];
            component.config.elements.optionSelected.classList.add(component.config.classNames.selected);
          }
          else if ((e.key === 'ArrowDown' || e.key === 'Down') && component.config.elements.optionSelected && component.currentOptionIndex !== component.dropdownOptions.length - 1) {
            component.config.elements.optionSelected.classList.remove(component.config.classNames.selected);
            component.currentOptionIndex += 1;
            component.config.elements.optionSelected = component.dropdownOptions[component.currentOptionIndex];
            component.config.elements.optionSelected.classList.add(component.config.classNames.selected);
          }
          else if (e.key === 'Escape' || e.key === 'Esc' || e.key === 'Tab') {
            component.toggleDropdownDisplay();
            component.selector.focus();
          }
          component.config.elements.optionSelected.focus();

          return false;
        }
      });
    };

    const  _toggleDropdownDisplay = (openDropdownClass) => {  
      if (openDropdownClass) {
        component.dropdownList.classList.add(openDropdownClass);
        component.dropdownList.classList.remove(component.config.classNames.hide);
      }
      else {
        component.dropdownList.classList.add(component.config.classNames.hide);
        if (component.dropdownList.classList.contains(component.config.classNames.dropdownOnTop)) {
          component.dropdownList.classList.remove(component.config.classNames.dropdownOnTop);
        }
        if (component.dropdownList.classList.contains(component.config.classNames.dropdownOnBottom)) {
          component.dropdownList.classList.remove(component.config.classNames.dropdownOnBottom);
        }
      }
    };

    component.config = _config;
    component.init = _init;
    component.bind = _bind;
    component.toggleDropdownDisplay = _toggleDropdownDisplay;
    component.selectorClick = _selectorClick;
    component.setDropdownHeight = _setDropdownHeight;
    component.keyPress = _keyPress;
    component.highlightOption = _highlightOption;
    component.optionHovered = _optionHovered;

    return component;
  };

  return footerSubsiteSelector;
});
